function formatCleverTapDate(dateInput) {
  const dateObj = new Date(dateInput);
  if (isNaN(dateObj.getTime())) {
    return 'Invalid date format';
  }
  const day = String(dateObj.getDate()).padStart(2, '0');
  const month = String(dateObj.getMonth() + 1).padStart(2, '0');
  const year = dateObj.getFullYear();
  const hours = String(dateObj.getHours()).padStart(2, '0');
  const minutes = String(dateObj.getMinutes()).padStart(2, '0');
  const seconds = String(dateObj.getSeconds()).padStart(2, '0');
  return `${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
}
document.addEventListener('DOMContentLoaded', function () {
  const utmParams = {};
  const urlParams = new URLSearchParams(window.location.search);
  Array.from(urlParams.keys()).forEach((key) => {
    if (key.toLowerCase().includes('utm')) {
      utmParams[key] = urlParams.get(key);
    }
  });
  console.log('CLEVERTAP-VISITEDs');
  clevertap?.event?.push('page_visited', {
    pageTitle: document.title,
    url: window.location.href,
    path: window.location.pathname,
    referringURL: document.referrer,
    ...utmParams,
    userAgent: navigator.userAgent,
    timestamp: formatCleverTapDate(new Date()),
  });
});
